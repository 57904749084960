<template>
  <div id="chart-dashboard">
    <canvas
      ref="chart"
      :height="height"
      :style="{width : '100%'}"
    />
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import { externalTooltipHandler } from '@/utils/chart';
import { COLOR_FIVE, COLOR_SIX, COLOR_TEN } from './constants';

Chart.register(...registerables);

export default {
  name: 'AnalyticsSingleChart',
  props: {
    content: {
      type: Object,
      required: true,
    },
    stat: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      default: 350,
    },
    vertical: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      chart: null,
      suggestedMax: 100,
    };
  },
  computed: {
    chartVariants() {
      return this.content.names.length;
    },
    colorScheme() {
      if (this.chartVariants === 5) {
        return COLOR_FIVE;
      }
      if (this.chartVariants === 10) {
        return COLOR_TEN;
      }
      return COLOR_SIX;
    },
    labels() {
      if (this.vertical) {
        return this.content.names.map((item) => this.prepareLabel(item));
      }
      return [`${this.stat.date} (${this.total} чел.)`];
    },
    total() {
      return this.stat.counts.reduce((acc, val) => acc + val, 0);
    },
    data() {
      // Приводим данные к 100%
      return this.stat.counts.map((item) => Math.round((item * this.suggestedMax) / this.total));
    },
  },
  watch: {
    stat: {
      deep: true,
      handler() {
        this.updateChart();
      },
    },
  },
  mounted() {
    this.createChart();
  },
  methods: {
    createChart() {
      const ctx = this.$refs.chart.getContext('2d');
      const {
        data, labels, suggestedMax, colorScheme,
      } = this;
      const tooltipPlugin = Chart.registry.getPlugin('tooltip');
      // eslint-disable-next-line no-unused-vars
      tooltipPlugin.positioners.cursor = (elements, eventPosition) => {
        const pos = tooltipPlugin.positioners.nearest(elements, eventPosition);
        if (pos === false) {
          return false;
        }

        return {
          x: eventPosition.x,
          y: eventPosition.y,
        };
      };
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels,
          datasets: [{
            labels: this.content.names,
            backgroundColor: colorScheme,
            borderColor: colorScheme,
            moreData: this.stat.counts,
            data,
          }],
        },
        options: {
          indexAxis: this.vertical ? 'y' : 'x',
          plugins: {
            legend: {
              display: false,
              position: 'top',
              // onClick: () => {},
              fullWidth: false,
              labels: {
                boxWidth: 12,
              },
            },
            tooltip: {
              // Disable the on-canvas tooltip
              enabled: false,
              xAlign: 'left',
              position: 'cursor',
              yAlign: 'center',
              callbacks: {
                title() {
                  return false;
                },
                label(context) {
                  const {
                    dataIndex,
                    dataset,
                  } = context;
                  return ` ${dataset.data[dataIndex]}% (${dataset.moreData[dataIndex]} чел.)`;
                },
                afterLabel(context) {
                  const {
                    dataIndex,
                    dataset,
                  } = context;
                  const dataSetLabel = dataset.labels[dataIndex];
                  return `${Array.isArray(dataSetLabel) ? dataSetLabel.join(' ') : dataSetLabel}`;
                },
              },

              external: externalTooltipHandler,
            },
          },

          scales: {
            [this.vertical ? 'y' : 'x']: [{
              gridLines: {
                color: 'rgba(0, 0, 0, 0.05)',
              },
            }],
            [this.vertical ? 'x' : 'y']: [{
              gridLines: {
                color: 'rgba(0, 0, 0, 0.05)',
              },
              ticks: {
                suggestedMax,
                maxTicksLimit: 6,
                callback(value) {
                  return `${value}%`;
                },
              },
            }],
          },
        },
      });
    },
    updateChart() {
      /**
       * Надо мутировать конфиг для изменения графика
       * @see https://www.chartjs.org/docs/2.7.0/developers/updates.html#adding-or-removing-data
       */
      const {
        data,
        labels,
        colorScheme,
      } = this;
      this.chart.data.labels = labels;
      this.chart.data.datasets = [{
        barThickness: 36,
        backgroundColor: colorScheme,
        borderColor: colorScheme,
        data,
      }];

      this.chart.update();
    },
    // Делаем метки у графиков одной длинны
    prepareLabel(label) {
      const MAX_LABEL_LENGTH = 15; // Максимальная длина метки

      if (label.length < MAX_LABEL_LENGTH) {
        return label;
      }
      return label.split(' ').reduceRight((acc, current) => {
        if (typeof acc === 'string') {
          const string = `${acc} ${current}`;
          if (string.length < MAX_LABEL_LENGTH) {
            return string;
          }
          return [acc, current];
        }
        return acc.push(current);
      }).map((item) => item.split(' ').reverse().join(' '))
        .reverse();
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
