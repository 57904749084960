<template>
  <VContainer
    fluid
    class="pa-0"
  >
    <DialogWrapper
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @click:outside="dialog = false"
    >
      <EventAnalyticsInfo
        v-if="dialog"
        :content="content"
        :stat="stat"
        :is-single="isSingle"
        :count="count"
        :index="startIndex"
        @close="dialog = false"
      />
    </DialogWrapper>

    <VRow align="center">
      <VCol cols="12">
        <h2
          data-test="event-analytics-full-name"
        >
          {{ count ? `${count}. ` : '' }}{{ title }}
        </h2>
      </VCol>
    </VRow>
    <VRow align="start">
      <VCol cols="8">
        <div
          v-if="!isSingle"
          class="d-flex justify-space-between"
        >
          <div
            v-if="showPrev"
            class="d-flex align-center"
          >
            <VBtn
              fab
              depressed
              max-width="36"
              max-height="36"
              class="mb-2"
              @click="prev"
            >
              <VIcon
                color="tt-black"
                size="16"
              >
                fal fa-angle-left
              </VIcon>
            </VBtn>
            <div class="text--button-label ml-4">
              Предыдущие<br>
              результаты
            </div>
          </div>
          <VSpacer />
          <div
            v-if="showNext"
            class="d-flex align-center"
          >
            <div class="text--button-label mr-4">
              Более поздние<br>
              результаты
            </div>
            <VBtn
              fab
              depressed
              max-width="36"
              max-height="36"
              class="mb-2"
              @click="next"
            >
              <VIcon
                color="tt-black"
                size="16"
              >
                fal fa-angle-right
              </VIcon>
            </VBtn>
          </div>
        </div>
        <AnalyticsSingleChart
          v-if="isSingle"
          :content="content"
          :stat="stat[0]"
        />
        <AnalyticsMultiChart
          v-else
          :content="content"
          :stat="preparedStat"
        />
      </VCol>
      <VCol cols="4">
        <EventAnalyticsComments
          :stat="preparedStat"
          :is-single="isSingle"
          @showMore="showMore"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import EventAnalyticsComments from '@/components/analytics/EventAnalyticsComments.vue';
import EventAnalyticsInfo from '@/components/analytics/EventAnalyticsInfo.vue';
import AnalyticsSingleChart from '@/components/analytics/AnalyticsSingleChart.vue';
import AnalyticsMultiChart from '@/components/analytics/AnalyticsMultiChart.vue';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';

export default {
  name: 'EventAnalyticsSegment',
  components: {
    DialogWrapper,
    EventAnalyticsComments,
    EventAnalyticsInfo,
    AnalyticsSingleChart,
    AnalyticsMultiChart,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    stat: {
      type: Array,
      required: true,
    },
    count: {
      type: Number,
      default: 1,
    },
    periodic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chart: null,
      dialog: false,
      startIndex: 0,
      TOTAL_SHOW: 5,
    };
  },
  computed: {
    title() {
      return this.content.question;
    },
    isSingle() {
      return !this.periodic;
    },
    totalStat() {
      return this.stat.length;
    },
    preparedStat() {
      return this.stat.slice(this.startIndex, this.startIndex + this.TOTAL_SHOW);
    },
    showPrev() {
      return this.startIndex + this.TOTAL_SHOW < this.totalStat;
    },
    showNext() {
      return this.startIndex - this.TOTAL_SHOW >= 0;
    },
  },
  methods: {
    showMore() {
      this.dialog = true;
    },
    next() {
      this.startIndex -= this.TOTAL_SHOW;
    },
    prev() {
      this.startIndex += this.TOTAL_SHOW;
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
