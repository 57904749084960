<template>
  <div id="chart-dashboard">
    <canvas
      ref="chart"
      :height="height"
      :style="{width : '100%'}"
      style="position:relative;"
    />
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import { externalTooltipHandler } from '@/utils/chart';
import { COLOR_FIVE, COLOR_SIX, COLOR_TEN } from './constants';

Chart.register(...registerables);

export default {
  name: 'AnalyticsMultiChart',
  props: {
    content: {
      type: Object,
      required: true,
    },
    stat: {
      type: Array,
      required: true,
    },
    height: {
      type: Number,
      default: 350,
    },
    vertical: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      chart: null,
      suggestedMax: 100,
    };
  },
  computed: {
    chartVariants() {
      return this.content.names.length;
    },
    colorScheme() {
      if (this.chartVariants === 5) {
        return COLOR_FIVE;
      }
      if (this.chartVariants === 10) {
        return COLOR_TEN;
      }
      return COLOR_SIX;
    },
    reversedStat() {
      return [...this.stat].reverse();
    },
    labels() {
      return this.reversedStat.map((stat, i) => ([stat.date, `(${this.totalArr[i]} чел.)`]));
    },
    totalArr() {
      return this.reversedStat.map((stat) => stat.counts.reduce((acc, val) => acc + val, 0));
    },
    datasets() {
      // Приводим данные к 100%
      return this.content.names.map((name, i) => {
        // data - результат в процентах
        const data = this.reversedStat.map((stat, j) => Math.round((stat.counts[i] * 100) / this.totalArr[j]));
        return {
          label: name,
          labels: this.content.names,
          backgroundColor: this.colorScheme[i],
          borderColor: this.colorScheme[i],
          // moreData - количественный результат
          moreData: this.reversedStat.map((stat) => stat.counts[i]),
          data,
        };
      });
    },
  },
  watch: {
    stat: {
      deep: true,
      handler() {
        this.updateChart();
      },
    },
  },
  mounted() {
    this.createChart();
  },
  methods: {
    createChart() {
      const ctx = this.$refs.chart.getContext('2d');
      const {
        datasets, labels, suggestedMax,
      } = this;
      const tooltipPlugin = Chart.registry.getPlugin('tooltip');
      // eslint-disable-next-line no-unused-vars
      tooltipPlugin.positioners.cursor = (elements, eventPosition) => {
        const pos = tooltipPlugin.positioners.nearest(elements, eventPosition);
        if (pos === false) {
          return false;
        }

        return {
          x: eventPosition.x,
          y: eventPosition.y,
        };
      };

      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels,
          datasets,
        },
        options: {
          scales: {
            xAxes: [{
              gridLines: {
                color: 'rgba(0, 0, 0, 0.05)',
              },
            }],
            yAxes: [{
              gridLines: {
                color: 'rgba(0, 0, 0, 0.05)',
              },
              ticks: {
                suggestedMax,
                maxTicksLimit: 6,
                callback(value) {
                  return `${value}%`;
                },
              },
            }],
          },
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
              // onClick: () => {},
              fullWidth: false,
              labels: {
                boxWidth: 12,
              },
            },
            tooltip: {
              // Disable the on-canvas tooltip
              enabled: false,
              mode: 'index',
              position: 'cursor',
              intersect: true,
              xAlign: 'left',
              yAlign: 'center',
              callbacks: {
                label(context) {
                  const { dataIndex, dataset } = context;
                  return ` ${dataset.data[dataIndex]}% (${dataset.moreData[dataIndex]} чел.)`;
                },
                afterLabel(context) {
                  const { datasetIndex, dataset } = context;
                  const dataSetLabel = dataset.labels[datasetIndex];
                  return ` ${dataSetLabel}`;
                },

              },

              // TODO В будущем нужно будет доработать кастомный тултип
              external: externalTooltipHandler,
            },
          },

        },
      });
    },
    updateChart() {
      /**
       * Надо мутировать конфиг для изменения графика
       * @see https://www.chartjs.org/docs/2.7.0/developers/updates.html#adding-or-removing-data
       */
      const { datasets, labels } = this;
      this.chart.data.labels = labels;
      this.chart.data.datasets = datasets;

      this.chart.update();
    },

  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
