<template>
  <VContainer
    fluid
  >
    <VRow
      v-if="error"
      data-test="event-analytics-item-panel-1"
    >
      <VCol data-test="event-analytics-item-error">
        {{ error }}
      </VCol>
    </VRow>
    <template v-else>
      <VRow>
        <VCol cols="12">
          <h1 class="tt-text-headline-1">
            {{ title }}
          </h1>
        </VCol>
      </VRow>
      <EventAnalyticsSegment
        v-for="(content, i) in contents"
        :key="content.id"
        :content="content"
        :periodic="periodic"
        :stat="statMap[content.id]"
        :count="contents.length > 1 ? i + 1 : 0"
      />
    </template>
  </VContainer>
</template>

<script>
import EventAnalyticsSegment from '@/components/analytics/EventAnalyticsSegment.vue';
import { formatDate } from '@/utils';

export default {
  name: 'EventAnalytics',
  components: {
    EventAnalyticsSegment,
  },
  data() {
    return {
      loading: false,
      periodic: false,
      event: {},
      error: '',
      contents: [],
      statMap: {},
    };
  },
  computed: {
    title() {
      return this.event.msg_title;
    },
  },
  watch: {
    $route() {
      this.getItem();
    },
  },
  mounted() {
    this.getItem();
  },
  methods: {
    getItem() {
      this.loading = true;
      this.entity = {};
      this.event = {};
      this.$repositories.event.getAnalytic(`${this.$route.params.id}`).then((r) => {
        const { data } = r.data;
        if (data) {
          this.contents = data.contents;
          this.periodic = data.periodic || false;
          const statMap = {};
          data.contents.forEach((item) => {
            statMap[item.id] = [];
          });
          data.stat.forEach((item) => {
            statMap[item.content_id].push(item);
          });
          Object.keys(statMap).forEach((key) => {
            statMap[key] = statMap[key].reverse().map((item) => {
              const stat = { ...item };
              stat.date = formatDate(stat.date);
              return stat;
            });
          });
          this.statMap = statMap;
        }
      }).catch((error) => {
        const { response } = error;
        if (response.status === 403 && response.data.error.code === 'ACCESS_DENIED') {
          this.error = response.data.error.message;
        }
      }).finally(() => {
        this.loading = false;
      });
      this.$repositories.event.list({ data: { filter: { id: this.$route.params.id } } }).then((r) => {
        const { data } = r.data;
        if (r.data) {
          [this.event] = data;
        }
      }).catch((error) => {
        console.warn(error);
      });
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
