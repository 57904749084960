<template>
  <VContainer
    fluid
    class="pa-0 analytics-info"
  >
    <VRow>
      <VCol cols="1" />
      <VCol cols="10">
        <VRow>
          <VCol
            lg="9"
            sm="12"
          >
            <VRow align="center">
              <VCol cols="12">
                <h1
                  data-test="event-analytics-full-name"
                  class="tt-text-headline-1"
                >
                  {{ count ? `${count}. ` : '' }}{{ title }}
                </h1>
              </VCol>
            </VRow>
            <VRow align="start">
              <VCol cols="12">
                <div
                  v-if="!isSingle"
                  class="d-flex justify-space-between"
                >
                  <div
                    v-if="showPrev"
                    class="d-flex align-center"
                  >
                    <VBtn
                      fab
                      depressed
                      max-width="36"
                      max-height="36"
                      class="mb-2"
                      data-test="event-analytics-prev-results"
                      @click="prev"
                    >
                      <VIcon
                        color="tt-black"
                        size="16"
                      >
                        fal fa-angle-left
                      </VIcon>
                    </VBtn>
                    <div class="text--button-label ml-4">
                      Предыдущие<br>
                      результаты
                    </div>
                  </div>
                  <VSpacer />
                  <div
                    v-if="showNext"
                    class="d-flex align-center"
                  >
                    <div class="text--button-label mr-4">
                      Более поздние<br>
                      результаты
                    </div>
                    <VBtn
                      fab
                      depressed
                      max-width="36"
                      max-height="36"
                      class="mb-2"
                      data-test="event-analytics-next-results"
                      @click="next"
                    >
                      <VIcon
                        color="tt-black"
                        size="16"
                      >
                        fal fa-angle-right
                      </VIcon>
                    </VBtn>
                  </div>
                </div>
                <template v-if="showChart">
                  <AnalyticsSingleChart
                    v-if="isSingle"
                    :content="content"
                    :stat="stat[0]"
                  />
                  <AnalyticsMultiChart
                    v-else
                    :content="content"
                    :stat="preparedStat"
                  />
                </template>
              </VCol>
            </VRow>

            <VRow align="baseline">
              <VCol
                cols="12"
                class="pb-0"
              >
                <div>Комментарии</div>
              </VCol>
            </VRow>
            <template v-if="showChart">
              <AnalyticsCommentsCell
                v-if="isSingle"
                :stat="stat[0]"
                expanded
              />
              <template v-else>
                <AnalyticsCommentsCell
                  v-for="(statCell, i) in preparedStat"
                  :key="`${startIndex}_${i}`"
                  :stat="statCell"
                  :expanded="i === activeCommentIndex"
                  @open="activeCommentIndex = i"
                />
              </template>
            </template>
          </VCol>
        </VRow>
      </VCol>
      <VCol
        cols="1"
        class="text-center"
      >
        <TTBtn
          color="tt-secondary"
          fab
          class="mb-2"
          data-test="event-analytics-close-button"
          @click="$emit('close')"
        >
          <VIcon
            color="tt-black"
            size="19"
          >
            fal fa-times
          </VIcon>
        </TTBtn>
        <div class="tt-text-caption tt-black--text text--lighten-2">
          Закрыть
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import AnalyticsSingleChart from '@/components/analytics/AnalyticsSingleChart.vue';
import AnalyticsMultiChart from '@/components/analytics/AnalyticsMultiChart.vue';
import AnalyticsCommentsCell from '@/components/analytics/AnalyticsCommentsCell.vue';

export default {
  name: 'EventAnalyticsInfo',
  components: {
    AnalyticsSingleChart, AnalyticsMultiChart, AnalyticsCommentsCell,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    stat: {
      type: Array,
      required: true,
    },
    count: {
      type: Number,
      default: 1,
    },
    index: {
      type: Number,
      default: 0,
    },
    isSingle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeCommentIndex: 0,
      showChart: false,
      startIndex: 0,
      TOTAL_SHOW: 5,
    };
  },
  computed: {
    title() {
      return this.content.question;
    },
    totalStat() {
      return this.stat.length;
    },
    preparedStat() {
      return this.stat.slice(this.startIndex, this.startIndex + this.TOTAL_SHOW);
    },
    showPrev() {
      return this.startIndex + this.TOTAL_SHOW < this.totalStat;
    },
    showNext() {
      return this.startIndex - this.TOTAL_SHOW >= 0;
    },
  },
  mounted() {
    // Так как компонент показывается в модальном окне, нуждна задержка на transition открытия
    setTimeout(() => {
      this.showChart = true;
    }, 500);
    this.startIndex = this.index;
  },
  methods: {
    next() {
      this.startIndex -= this.TOTAL_SHOW;
      this.activeCommentIndex = 0;
    },
    prev() {
      this.startIndex += this.TOTAL_SHOW;
      this.activeCommentIndex = 0;
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
