<template>
  <VRow
    align="baseline"
    class="comment-cell"
  >
    <VCol class="tt-black--text text--lighten-2 comment-cell__date">
      {{ date }}
    </VCol>
    <VCol v-if="comments.length">
      <ul>
        <li
          v-for="(comment, i) in preparedComments"
          :key="i"
          class="comment-cell__comment py-4"
        >
          <SExpandable :max-lines="2">
            {{ comment }}
          </SExpandable>
        </li>
      </ul>
      <div
        v-if="!commentsToShow"
        class="text--link"
        @click="openComments"
      >
        Посмотреть комментарии ({{ commentsLeft }})
      </div>
      <div
        v-else
        class="d-flex justify-space-between pt-4"
      >
        <div
          v-if="showMore"
          class="text--link"
          @click="increaseComments"
        >
          Показать еще {{ commentsLeft > SHOW_INCREMENT? SHOW_INCREMENT : commentsLeft }} из {{ commentsLeft }}
        </div>
        <VSpacer />
        <div
          class="text--link"
          @click="commentsToShow = 0"
        >
          Скрыть
        </div>
      </div>
    </VCol>
    <VCol v-else>
      Нет комментариев
    </VCol>
  </VRow>
</template>

<script>
import SExpandable from '@/components/ui/SExpandable.vue';

// По сколько комментариев добавлять для показа
const SHOW_INCREMENT = 5;

export default {
  name: 'AnalyticsCommentsCell',
  components: { SExpandable },
  props: {
    stat: {
      type: Object,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      commentsToShow: 0,
      SHOW_INCREMENT,
    };
  },
  computed: {
    comments() {
      return this.stat.comments || [];
    },
    preparedComments() {
      return this.comments.slice(0, this.commentsToShow);
    },
    showMore() {
      return this.comments.length > this.preparedComments.length;
    },
    // сколько осталось не показанных комментариев
    commentsLeft() {
      return this.comments.length - this.preparedComments.length;
    },
    date() {
      return this.stat.date;
    },
  },
  watch: {
    expanded: {
      handler(val) {
        if (!val) {
          this.commentsToShow = 0;
        } else {
          this.commentsToShow = Math.min(this.SHOW_INCREMENT, this.comments.length);
        }
      },
      immediate: true,
    },
  },
  methods: {
    openComments() {
      this.increaseComments();
      this.$emit('open');
    },
    // Увеличиваем число показываемых комментариев,
    // либо на значение SHOW_INCREMENT, либо на commentsLeft, если осталось меньше
    increaseComments() {
      this.commentsToShow += Math.min(this.commentsLeft, SHOW_INCREMENT);
    },
  },
};
</script>

<style>
  .comment-cell .comment-cell__date {
    flex: 0 0 80px;
  }
  .comment-cell li.comment-cell__comment {
    border-bottom: 1px solid #EBECED;
    list-style: none;
  }
  .comment-cell ul {
    padding-left: 0;
  }

</style>
