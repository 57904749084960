<template>
  <VCard class="comments-card pa-2">
    <VCardTitle>
      Комментарии
    </VCardTitle>
    <VCardSubtitle
      v-if="date"
      class="tt-black--text text--lighten-2"
    >
      {{ date }}
    </VCardSubtitle>
    <VCardText v-if="total > 0">
      <ul class="pl-0">
        <li
          v-for="comment in preparedComments"
          :key="comment"
          class="comment-card__wrapper"
        >
          <div class="comment-card__comment">
            {{ comment }}
          </div>
        </li>
        <li
          v-if="showMore"
          class="comment-card__wrapper text--link"
          @click="$emit('showMore')"
        >
          Посмотреть все комментарии ({{ total }})
        </li>
        <li
          v-else-if="!isSingle"
          class="comment-card__wrapper text--link"
          @click="$emit('showMore')"
        >
          Посмотреть комментарии к другим результатам
        </li>
      </ul>
    </VCardText>
    <VCardText v-else>
      Нет комментариев
      <div
        v-if="!isSingle"
        class="pt-3 mt-3 comment-card__show-more text--link"
        @click="$emit('showMore')"
      >
        Посмотреть комментарии к другим результатам
      </div>
    </VCardText>
  </VCard>
</template>

<script>

export default {
  name: 'EventAnalyticsComments',
  props: {
    stat: {
      type: Array,
      required: true,
    },
    isSingle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    commentStat() {
      return this.stat[0] || {};
    },
    comments() {
      return this.commentStat.comments || [];
    },
    preparedComments() {
      return this.comments.slice(0, 2);
    },
    showMore() {
      return this.comments.length > 0;
    },
    total() {
      return this.comments.length;
    },
    date() {
      return this.commentStat.date;
    },
  },
};
</script>

<style>
  .comments-card {
    box-shadow: 0 2px 24px rgba(11, 18, 24, 0.08)!important;
    border-radius: 8px;
  }

  .comment-card__comment {
    color: #0B1218;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    display: -webkit-box;
    line-clamp: 4;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    max-height: 5.6em;
    line-height: 1.4em;
  }

  .comment-card__wrapper {
    list-style-type: none;
  }

  .comment-card__wrapper:not(:last-child) {
    border-bottom: 1px solid #EBECED;
    margin-bottom: 8px;
    padding-bottom: 8px;
  }

  .comment-card__show-more {
    border-top: 1px solid #EBECED;
  }
</style>
